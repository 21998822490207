import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import orders from './modules/orders'
import biz from './modules/biz'

import access from './modules/Business/access'
import bizAccounts from './modules/Business/accounts'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    orders,
    biz,
    access,
    bizAccounts,
  }
})
export default store