<template lang="pug">
div
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["biz", "useraccess", "bizAccounts"]),
    userId() {
      if (this.$auth.user()) return this.$auth.user()._id;
      return null;
    },
  },
  watch: {
    userId() {
      this.load();
    },
  },
  methods: {
    ...mapActions(["setUserAccesses", "setBizAccounts", "setBiz"]),
    async load() {
      if (!this.$auth.user() || !this.$auth.user()._id) {
        this.setUserAccesses([]);
        return;
      }
      const userId = this.$auth.user()._id;
      const params = {
        criteria: {
          user: userId,
        },
      };
      const res = await this.axios.post("/bizs/access/list", params);
      this.setUserAccesses(res.data);
      this.loadBizs();
    },
    async loadBizs() {
      if (!this.useraccess) return;
      const bizIds = _.chain(this.useraccess).pluck("biz").compact().value();
      if (!bizIds || !bizIds.length) {
        this.setBizAccounts([]);
        this.setBiz();
        return;
      }
      const params = {
        criteria: { _id: { $in: bizIds } },
        select: "name address",
      };
      const res = await this.axios.post("/bizs/list", params);
      this.setBizAccounts(res.data);
    },
    reset() {
      this.setUserAccesses([]);
      this.setBizAccounts([]);
    },
  },
  mounted() {
    this.reset();
    this.load();
    EventBus.$on("login", this.load);
    EventBus.$on("logout", this.reset);
  },
  destroyed() {
    EventBus.$off("login");
    EventBus.$off("logout");
  },
};
</script>