<template lang="pug">
v-container
  .text-center.sticky
    v-btn(
      @click="load()",
      rounded,
      outlined,
      color="secondary",
      small,
      :loading="loading"
    )
      v-icon(small, left) mdi-sync
      span Refresh
  v-tabs(centered, fixed-tabs)
    v-tab
      v-badge(right, color="green")
        span(slot="badge") {{ openOrders.length }}
        span Open Orders
    v-tab
      v-badge(right, color="red")
        span(slot="badge") {{ closedOrders.length }}
        span Closed Orders
    v-tab-item
      OrderList(:orders="openOrders")
    v-tab-item
      OrderList(:orders="closedOrders")
</template>

<script>
import moment from "moment";
import OrderList from "./List";
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  components: {
    OrderList,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["orders", "biz"]),
    openOrders() {
      return _.chain(this.orders)
        .filter((o) => {
          return o.status >= 0 && o.status < 3;
        })
        .sortBy("created", -1)
        .value();
    },
    closedOrders() {
      return _.chain(this.orders)
        .reject((o) => {
          return o.status >= 0 && o.status < 3;
        })
        .sortBy("created", -1)
        .value();
    },
  },
  methods: {
    ...mapActions(["setOrders"]),
    async load() {
      if (!this.biz) return;
      const bizId = this.biz._id;
      const start = moment().startOf("days").unix() * 1000;
      const options = {
        criteria: {
          "seller.id": bizId,
          "orderer.type": 0,
          needed: { $gte: start },
        },
      };
      this.loading = true;
      const res = await this.axios.post("/orders/list", options);
      this.setOrders(res.data);
      this.loading = false;
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 4;
  background: #F0F2F5;
  padding: 12px;
  margin-bottom: 12px;
}
</style>