import Terms from './Terms/index.vue'
import Privacy from './Privacy/index.vue'
import BrandResources from './BrandResources/index.vue'

export default [{
  path: '/terms',
  component: Terms,
}, {
  path: '/privacy',
  component: Privacy,
}, {
  path: '/brandresources',
  component: BrandResources,
}]