<template lang="pug">
a(@click="resetPWD()") Forgot your password?
</template>

<script>
export default {
  methods: {
    resetPWD() {
      this.$router.push("/resetpwd");
    },
  },
};
</script>

<style scoped>
a {
  margin-top: 10px;
  float: right;
  font-size: 13px;
  color: rgba(28, 31, 52, 0.6);
}
</style>