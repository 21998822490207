<template lang="pug">
div
  div(v-for="(item, index) in modifiers", :key="index") {{ item }}
</template>

<script>
export default {
  props: ["items"],
  computed: {
    modifiers() {
      const filter = this.$options.filters.modifiers;
      return filter(this.items);
    },
  },
};
</script>
