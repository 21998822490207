<template lang="pug">
v-container
  .d-flex.flex-row.align-center.justify-space-between
    .sl-title Brand Resources
  v-divider.mb-2
  div We build technologies to power the restaurants and local businesses to interact with customers.
  div As we grow, we will evolve a formal guideline for our branding assets.
  .subtitle-2.my-5 iOS App Logo
  div
    v-img(height="160" width="160" :src='ios.icon' contain )
    .body-2 Download
    a(v-for='(item, index) in ios.files' :key='index' :href='item.path' download).mr-3 {{ item.name }}
  .subtitle-2.my-5 iOS App Logo with Name
  div
    v-img(height="80" width="320" :src='ios_name.icon' contain )
    .body-2 Download
    a(v-for='(item, index) in ios_name.files' :key='index' :href='item.path' download).mr-3 {{ item.name }}


</template>

<script>
export default {
  data() {
    return {
      ios: {
        icon:
          "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2Fselflane_ios.png?alt=media&token=11f8a4f3-9f57-4672-96a1-430505fa9ae2",
        files: [
          {
            name: "png",
            path:
              "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2Fselflane_ios.png?alt=media&token=11f8a4f3-9f57-4672-96a1-430505fa9ae2"
          },
          {
            name: "eps",
            path:
              "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2FSelflane.eps?alt=media&token=04c614d4-4553-4006-8589-540e384b4935"
          }
        ]
      },
      ios_name: {
        icon:
          "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2Fselflane_ios_name.png?alt=media&token=5ca7e1e4-b9e0-4fd9-ac59-2659aabfa3c8",
        files: [
          {
            name: "png",
            path:
              "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/brand_resources%2FiOS_app%2Fselflane_ios_name.png?alt=media&token=5ca7e1e4-b9e0-4fd9-ac59-2659aabfa3c8"
          }
        ]
      }
    };
  }
};
</script>