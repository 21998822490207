<template lang="pug">
.my-2
  Cell.mb-3(:order="item", v-for="item in items", :key="item._id")
</template>

<script>
import Cell from "./Cell/index";
import _ from "underscore";

export default {
  props: ["orders"],
  components: {
    Cell,
  },
  computed: {
    items() {
      return _.sortBy(this.orders, (o) => {
        return -o.needed;
      });
    },
  },
};
</script>