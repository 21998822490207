import _ from 'underscore'

const state = {
  useraccess: [], // access defined by user
  bizaccess: [] // access defined by biz
}

const getters = {
  useraccess: state => state.useraccess,
  bizaccess: state => state.bizaccess
}

const actions = {
  setUserAccesses: ({
    commit
  }, bizaccess) => {
    commit('setUserAccesses', bizaccess)
  },
  updateUserAccess: ({
    commit
  }, data) => {
    commit('updateUserAccess', data)
  },
  addUserAccess: ({
    commit
  }, data) => {
    commit('addUserAccess', data)
  },
  removeUserAccess: ({
    commit
  }, data) => {
    commit('removeUserAccess', data)
  },
  setBizAccesses: ({
    commit
  }, bizaccess) => {
    commit('setBizAccesses', bizaccess)
  },
  updateBizAccess: ({
    commit
  }, data) => {
    commit('updateBizAccess', data)
  },
  addBizAccess: ({
    commit
  }, data) => {
    commit('addBizAccess', data)
  },
  removeBizAccess: ({
    commit
  }, data) => {
    commit('removeBizAccess', data)
  }
}

const mutations = {
  setUserAccesses(state, data) {
    state.useraccess = data
  },
  updateUserAccess(state, data) {
    state.useraccess = _.map(state.useraccess, o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addUserAccess(state, data) {
    state.useraccess.push(data)
  },
  removeUserAccess(state, data) {
    state.useraccess = _.reject(state.useraccess, o => {
      return (o._id === data._id)
    })
  },
  setBizAccesses(state, data) {
    state.bizaccess = data
  },
  updateBizAccess(state, data) {
    state.bizaccess = _.map(state.bizaccess, o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addBizAccess(state, data) {
    state.bizaccess.push(data)
  },
  removeBizAccess(state, data) {
    state.bizaccess = _.reject(state.bizaccess, o => {
      return (o._id === data._id)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}