<template lang="pug">
.login-container
  vue-headful(:title="title", :description="description")
  .login
    Head
    Form
</template>

<script>
import Head from "./Head";
import Form from "./Form/index";

export default {
  components: { Head, Form },
  data() {
    return {
      app_name: process.env.VUE_APP_NAME || "Selflane",
    };
  },
  computed: {
    title() {
      return this.app_name + ": Login";
    },
    description() {
      if (this.app_name == "Selflane Business") {
        return "Login to Selflane Business to manage your restaurant.";
      }
      return "Login to Selflane to access hundreds of restaurants and order food online.";
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.login {
  width: 95%;
  max-width: 390px;
}
</style>
