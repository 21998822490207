const state = {
  biz: null,
  bizPhone: null
}

const getters = {
  biz: state => state.biz,
  bizPhone: state => state.bizPhone
}

const actions = {
  setBiz: ({
    commit
  }, biz) => {
    commit('setBiz', biz)
  }
}

const mutations = {
  setBiz(state, biz) {
    state.biz = biz
    if (biz && biz.phones && biz.phones.length > 0) {
      state.bizPhone = biz.phones[0].number
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}