import _ from "underscore";

const state = {
  bizAccounts: []
};

const getters = {
  bizAccounts: state => state.bizAccounts
};

const actions = {
  setBizAccounts: ({
    commit
  }, data) => {
    commit("setBizAccounts", data);
  },
  addBizAccount: ({
    commit
  }, data) => {
    commit('addBizAccount', data)
  },
  updateBizAccount: ({
    commit
  }, data) => {
    commit("updateBizAccount", data);
  },
  removeBizAccount: ({
    commit
  }, data) => {
    commit("removeBizAccount", data);
  },
};

const mutations = {
  setBizAccounts(state, data) {
    state.bizAccounts = data;
  },
  addBizAccount(state, data) {
    state.bizAccounts.push(data)
  },
  updateBizAccount(state, data) {
    state.bizAccounts = _.map(state.bizAccounts, o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeBizAccount(state, data) {
    state.bizAccounts = _.reject(state.bizAccounts, o => {
      return o._id === data._id;
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};