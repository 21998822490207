function dayschedule (input) {
  var str = []
  input.forEach(o => {
    if (o.range && o.range.length === 2) {
      var begin = fromMinutesToTime(o.range[0])
      var end = fromMinutesToTime(o.range[1])
      str.push(begin + ' - ' + end)
    }
  })
  return str.join(', ')
}

function fromMinutesToTime (minutes) {
  if (isNaN(minutes)) return 'N/A'
  minutes = Math.round(minutes) // ensure integer
  var hh = Math.floor(minutes / 60)
  var ampm = 'AM'
  if (hh >= 12) {
    ampm = 'PM'
    hh -= 12
  }
  if (hh === 0) hh = 12
  hh = String(hh)
  var mm = String(minutes % 60)
  if (mm.length === 1) mm = '0' + mm
  return hh + ':' + mm + ' ' + ampm
}

export default dayschedule
