<template lang="pug">
v-dialog(v-model="dialog", max-width="500", v-if="order.status !== -1")
  template(v-slot:activator="{ on }")
    v-btn(text, color="warning", v-on="on") Cancel
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Cancel Order
    v-card-text In canceling this order, you agree to refund the customer if there was any payment associated with this order. For online orders, we would refund customer's online payments.
    v-container
      v-text-field(label="Reason", v-model="reason", autofocus)
      v-layout
        v-spacer
        v-btn(text, @click="dialog = false") Back
        v-btn(
          text,
          :disabled="!reason.length",
          :loading="loading",
          @click="submit()"
        ) Proceed
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      reason: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async submit() {
      if (!this.order) return;
      const options = {
        criteria: { _id: this.order._id },
        cancelNote: this.reason,
      };
      this.loading = true;
      try {
        const res = await this.axios.post("/orders/cancel", options);
        this.dialog = false;
        this.updateOrder(res.data);
      } catch (err) {
        alert(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>