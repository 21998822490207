<template lang="pug">
v-container(v-if="$auth.check()")
  v-row(dense)
    v-col(
      cols="12",
      sm="6",
      md="4",
      v-for="item in biz_items",
      :key="item._id"
    )
      v-card.pa-3(@click="selectBiz(item)", outlined, height="100%")
        .text-center
          .title.mb-2 {{ item.name }}
          span {{ item.address | address }}
  UserAccess
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import UserAccess from "./User/UserAccess";

export default {
  components: {
    UserAccess,
  },
  computed: {
    ...mapGetters(["useraccess", "bizAccounts"]),
    biz_items() {
      return _.chain(this.bizAccounts).sortBy("name").value();
    },
  },
  methods: {
    ...mapActions(["setBiz"]),
    selectBiz(item) {
      this.setBiz(item);
      this.$router.push("/orders");
    },
  },
};
</script>
