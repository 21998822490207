<template lang="pug">
div
  div(v-for="(dish, index) in order.dishes", :key="index + 'dish'")
    .item
      v-flex
        .item-name {{ dish.quantity }} &#215; {{ dish.name }}
          span.ml-4.red--text(v-if="dish.pctOff > 0") {{ dish.pctOff }}% off
        Modifiers.item-detail(:items="dish.modifiers")
      div {{ dish.unitPrice * dish.quantity | currency }}
    .sl-dash-divider
  div(v-for="(item, index) in order.gifts", :key="index + 'gift'")
    .item
      v-flex
        .item-name Gift Card
      div {{ item.amount | currency }}
    .sl-dash-divider
  div(v-for="(item, index) in order.vouchers", :key="index + 'voucher'")
    .item
      v-flex
        .item-name {{ item.name }}
      .red--text {{ -item.amount | currency }}
    .sl-dash-divider
</template>

<script>
import Modifiers from "./Modifiers";

export default {
  props: ["order"],
  components: { Modifiers },
};
</script>

<style scoped>
.item {
  display: flex;
  align-items: center;
  min-height: 54px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: black;
}
.item-name {
  font-size: 17px;
  font-weight: 500;
}
.item-detail {
  font-size: 15px;
}
</style>
