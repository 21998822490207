<template lang="pug">
div
  .d-flex.flex-row.flex-wrap.align-center.justify-center
    a.mx-5.my-2.link-item(href="https://pos.selflane.com", target="_blank") Point of Sale
    a.mx-5.my-2.link-item(href="https://docs.selflane.com", target="_blank") Documentation
    a.mx-5.my-2.link-item(href="https://help.selflane.com", target="_blank") Help Center
  .text-center
    AppLinks
</template>

<script>
import AppLinks from "./AppLinks";

export default {
  components: { AppLinks },
};
</script>

<style scoped>
.link-item {
  color: rgba(246, 247, 252, 0.7);
  font-size: 14px;
  line-height: 30px;
}

a {
  text-decoration: none;
  color: white;
  line-height: 25px;
  font-size: 12px;
}
</style>
