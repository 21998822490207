<template lang="pug">
v-container
  h1.title.my-3 SelfLane Terms of Service
  p.caption Last Updated: Jan 15, 2020
  p Welcome to Selflane. We developed a marketplace service to make it easy for restaurants to connect with their customers and manage their orders and business so you can focus on preparing the best food for your customers. We also make it easy for customers to make little effort to get to the point of enjoying their food.

  p We want this Terms of Service to be readable, but should you have any questions or concerns or would simply like to better understand how we do things at Selflane, please do not hesitate to 
    a(href='mailto:support@selflane.com') contact us
    span .

  p Below is a quick description of the sections in this Agreement, but there are import details in the whole document, so you should read it.

  h3.title.my-3 The Selflane Services
  p We provide a marketplace service to connect the restaurants with their customers; we will respect and protect both the privacy of the restaurants and their customers, their data and personal information.

  h3.title.my-3 Registering your business with Selflane
  p You provide us with basic information about your business and your identity; we will seek to verify your information (we may work with third parties to do so) and approve your account unless deemed risky (by us or our payment processors). You give us permission to do all this, and to periodically update the information or we otherwise must decline to off Selflane Services to you.

  h3.title.my-3 Receiving your funds from order transactions
  p Funds from your transactions (minus the transaction fees) are paid to you on Monday every week once your Selflane Account is approved. Initial payouts for your card transactions are typically sent to your bank within a week on the next transaction date. A certain amount of your funds may be subject to an additional hold period with terms determined by your perceived risk and transaction history. You are responsible for refunds, returns, and liable for customer disputes (chargebacks).

  h3.title.my-3 Termination and Other Legal Terms
  p We can terminate this agreement at any time (especially if you do something bad). You can also terminate anytime. Termination is effective immediately. Termination does not alter your liability for processed payments or related chargebacks. This section also includes all the extra legal stuff they make us add (e.g. indemnification, warranties, assignment).

  Payments

</template>

<script>
import Payments from "./Payments";
export default {
  components: { Payments }
};
</script>
