function orderType(type) {
  switch (type) {
    case 'pickup':
      return 'pick up'
    case 'dinein':
      return 'dine in'
    case 'gc':
      return 'gift card'
    default:
      return type
  }
}

export default orderType