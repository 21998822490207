<template lang="pug">
v-card
  v-toolbar(dark, color="light-blue", dense, flat)
    .subtitle-2 \#{{ order.orderNumber }} {{ localOnline }}/
      span.text-capitalize {{ order.type | orderType }}
    v-spacer
    .subtitle-2 {{ order.needed | moment('ddd M/D, h:mmA') }}
  v-card-text
    .text-center
      .subtitle-2.red--text.my-2(
        v-if="order.cancelNote && order.cancelNote.length"
      ) {{ order.cancelNote }}
    .text-center.subtitle-2 {{ status }}
    .d-flex.flex-row.justify-center.my-2
      v-btn.mr-2(
        v-if="order.status >= 0 && order.status < 2",
        depressed,
        rounded,
        small,
        color="secondary",
        dark,
        @click="updateStatus(2)"
      ) Ready
      v-btn(
        depressed,
        rounded,
        small,
        color="secondary",
        dark,
        v-if="order.status >= 0 && order.status < 3",
        @click="updateStatus(3)"
      ) Fulfilled
    .text-center
      .sl-title {{ order.customer.name }}
        span.ml-2 {{ order.customer.phone | phone }}
      .body-2 {{ order.customer.address | address }}
    .subtitle-2.my-1.success--text(v-if="order.note && order.note.length") {{ order.note }}
    Items(:order="order")
    Payment(:order="order")
    .text-center
      Cancel(:order="order")
</template>

<script>
import Items from "./Items";
import Payment from "./Payment";
import Cancel from "./Cancel";
import { mapActions } from "vuex";

export default {
  props: ["order"],
  components: {
    Items,
    Cancel,
    Payment,
  },
  data() {
    return {
      cancelDialog: false,
    };
  },
  computed: {
    toolbarColor() {
      if (this.order && this.order.status === -1) return "red";
      return "light-blue";
    },
    localOnline() {
      if (this.order && this.order.orderer && this.order.orderer.type === 1)
        return "Local";
      else return "Online";
    },
    status() {
      if (!this.order) return "";
      switch (this.order.status) {
        case -1:
          return "Canceled and refunded";
        case 0:
          return "Created";
        case 1:
          return "Processing";
        case 2:
          return "Ready";
        case 3:
          return "Fulfilled";
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    updateStatus(status) {
      const data = {
        orderId: this.order._id,
        status: status,
      };
      this.axios
        .post("/orders/updateStatus", data)
        .then((response) => {
          if (response && response.data) {
            this.updateOrder(response.data);
          }
        })
        .catch((err) => {
          if (err.response) alert(err.response.data);
        });
    },
  },
};
</script>