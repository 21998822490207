<template lang="pug">
v-app-bar(app, dense, dark, flat, color="primary")
  v-btn(icon, @click="toHome()")
    v-icon mdi-home
  .title Selflane Web POS
  v-spacer
  v-btn(text, @click="logout", v-if="$auth.check()") Logout
  v-btn(text, to="/login", v-else) Login
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
    };
  },
  methods: {
    toHome() {
      const currentPath = this.$router.history.current.path;
      if (currentPath != "/") this.$router.push("/");
    },
    logout() {
      this.$auth.logout();
    },
  },
};
</script>
