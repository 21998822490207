import Vue from 'vue'
import Router from 'vue-router'
import Home from './../components/Home'
import Orders from './../components/Order/index.vue'
import footerRoute from '@/components/Footer/route'
import loginRoute from '@/components/User/Login/route'

Vue.use(Router)

const baseRoutes = [{
    path: '/', name: 'Home', component: Home, meta: { auth: true }
}, {
    path: '/orders', component: Orders, meta: { auth: true }
}, {
    path: '*', redirect: '/'
}]

const routes = baseRoutes.concat(loginRoute, footerRoute)

export default new Router({
    mode: 'history',
    linkActiveClass: 'is-active',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: routes
})